:root {
  min-width: 320px;

  --cloth-shadow: #92490db3;
  /*--tint-color: rgba(0, 0, 0, 0.15);*/
  --tint-color: rgba(109, 39, 11, 0.25);
  --tint-color-2: rgba(109, 39, 11, 0.25);
  --column-rule-color: #612e04b3;
  --button-color: darkslategrey;
  
}

html {
  position: relative;
  min-height: 100%;
}

body {
  min-width: 320px;
  height: 100%;
  width: auto !important;
  background-color: linen;
  background: linear-gradient(90deg, var(--cloth-shadow) 0%, rgba(255,255,255,0) 15%, rgba(112,231,255,0) 85%, var(--cloth-shadow) 100%),
              url('cloth2_s.jpg');
}

.App, div {
  text-align: center;
}

h2, h3, .text-content {
  margin-left: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: left;
  break-after: avoid;
}

#main-heading {
  text-shadow:
  /*  
  0 0 2px #612e04ff,
  0 0 2px #612e04ff,
  0 0 2px #612e04ff,
  0 0 2px #612e04ff,
  */
  0 0 3px #ffffffff,
  0 0 3px #ffffffff,
  0 0 4px #ffffffff;
}


#location {
  
  /*margin: 0;*/
  margin-left: 100px;
  margin-top: 0.5em;

  
  text-shadow:  
  0 0 3px #ffffffff/*,
  
  0 0 3px #ffffffff,
  0 0 4px #ffffffff;
  */
}



/*
.tantoCursor {
  cursor: url('./tanto_cursor.png'), auto;
}
*/

.centered-label {
  width: 100%;
  /*display: flex;*/
  /*align-items: center;*/
  vertical-align: center;
}

[class^="symbol"] {
  /*float: right;*/
  /*vertical-align: center;*/
  background-color: white;
  border-radius: 20%;
  background-size: 0px!important;
  font-size: 2.2rem;
  height: 2.15rem;
  width: 2.15rem;
  overflow: hidden;
  margin-right: 1rem;
  border: 3px solid darkslategray;
}

.symbol-expand {
  float: right;
  color: seagreen;
}
.symbol-contract {
  float: right;
  color: darkred; 
}
.symbol-mail {
  float: left;
  border: none;
  background-color: transparent;
  /*margin-left: 1em;*/
}

.inline-block {
  text-align: left;
  display: inline-block;
}

.pointerCursor {
  cursor: pointer;
}



.emimg {
  position: relative;
  top: 0.45em;
  max-height: 1.5em;
  max-width: 6em;
  object-fit:contain;
}

form {
  position: relative;
  background-color: #92490d66;
  border-radius: 0.3em;
  border: 1px solid #612e04ff;
  padding: 1em;
  min-width: 270px;
}

form, form div, form table {
  text-align: left;
}

form td {
  vertical-align: top;
}

textarea {
  width: 96%;
}

input, textarea {
  border-radius: 0.5em;
  border: 1px solid darkslategray;
}

input {
  /*width: 15em;*/
  width: 12rem;
  height: 2em;
  margin-top: 0.2em;
  
}

textarea {
  resize: vertical;
}

.msg-chars {
  font-size: 0.8em;
  line-height: 1.2em;
  color: darkslategray;
}

.hum-check-instr {
  font-size: 0.8em;
  line-height: 1.2em;
  margin-top: 1em;
}

.sendFormButton {
  width: 200px;
  height: 5em;
  padding: 0;
  margin: 0;
  margin-top: 2em;
  background-color: darkslategray;
  color: lightgray;
  border-radius: 0.5em;
  font-weight: bolder;
  font-size: large;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}



/*
.text-part {
  display: flex;
  flex-direction: column;
  height: 100%;
  height: calc(50vh - 20em -256px)!important;
  overflow:scroll;
}
*/

/*
body {
  overflow:hidden;
}
*/

.text-content {
  /*
  flex: 1 0 auto;
  justify-self: stretch;
  */
  /*height: calc(100% - 20em - 256px)!important;*/
  /*height: calc(50vh - 20em -256px)!important;*/
  /*overflow: scroll;*/

  line-height: 1.5em;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 13rem;
  margin-left: 15%;
  margin-right: 15%; 
  margin-top: 3rem; 
}

.columned {
  columns: 400px 2;
  column-gap: 40px;
  column-rule: 4px double var(--column-rule-color);
  padding-bottom: 1.5em
}
@media screen and (min-width: 1600px) {
  .columned {
    columns: 400px 3;
  }
}


.avoid-break {
  page-break-inside: avoid;
  break-inside: avoid;
}

.expandableSection {
  text-align: left;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.imstrip {
  position: fixed;
  min-width: 400px;
  left: 0;
  bottom: 8rem;
  height: 5rem;
  width: 100%;
  background-image: url('jujutsu2.svg'), url('jujutsu1.svg'), url('jojutsu.svg');
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: bottom left, bottom center, bottom right;
  background-size:50% 50%, 50% 50%, 50% 70%;
}

hr {
  margin: 0;
  height: 2px;
  background-color: black;
}

header {
  background-color: white;
  position: relative;
  overflow: hidden;
  height: 256px;
  width: 100%;
  background-image: url('landmark2.png'), url('kenjutsu_light.svg'), url('shotou_3.svg');
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: top left, center right 0.7em, bottom center;
  background-size:512px 256px, 200px 200px, 70% 70%;
}

#langButtons {
  display: flex;
  flex-direction: column;
  /*align-items: flex-end;
  vertical-align:middle;*/
  align-items: center;
  justify-content: center;
  /*margin-right: 1em;*/
  padding-left: 0.3em;
  padding-right: 0.3em;
  /*font-weight: bold;
  font-size: 2.5em;
  line-height: 2.5em;*/
}

nav button {
  float: left;
  background-color: var(--button-color);
  color: white;
  width: 3.3em;
  height: 2.5rem;
  max-height: 2.5rem;
  border-radius: 20%;
  margin: 0.2em;
  margin-left: 0;
  margin-right: 0.5em;
  font-size: 0.6em;
  font-weight: bold;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 8rem;
  background-color: black;
  color: white;
  text-align: center;
  font-size: 1.4em;
  /*background-image: url('shotou_3.png'), url('tre2.png');
  background-repeat: no-repeat, no-repeat;
  background-position: center center, bottom left;
  background-size:auto 5em, cover;*/
  /*
  background-image: url('tre2_16_25_.jpg');
  */
  background-image: url('tre8.jpg');
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: cover;
  
  
}

footer div {
  margin: 1.5em;
  background-color: rgba(0, 0, 0, 0);
  padding: 0.2em;
  padding-left: 1.5em;
  padding-right: 0.5em;
  background-image: radial-gradient(black, black, black, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0));
  border-radius: 1em;
}

footer div.left {
  float: left;
  margin-top: 0.3em;
  margin-left: 0.3em;
  margin-right: 0.3em;
  padding-left: 0.5em;
  text-align: left;
  background-color: #00000099;
}

footer div.right {
  margin-top: 0.3em;
  float: right;
}

footer div.left a {
  /*display: inline-block;*/
  width: 100%;
  text-align: left;
  color: lime;
}

#h1im {
  position: absolute; 
  bottom: 0em;
  /*background: url('shotou_2.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 80% 0%;*/
  width: 100%;
  height: 30%;
  font-size: 1.5rem;
  /*color: darkslategrey;*/
  color: #612e04ff;
}


body, html, code { 
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3 {
  font-family: 'Roboto Condensed', sans-serif;
}

h1 {
  margin: 0;
  margin-top: 40px;
  margin-left: 120px;
}

nav {
  font-size: 2em;
}


.privacy-summary {
  font-weight: 800;
}

.columned p {
  margin-top: 0;
}


h3 {
  padding-top: 0em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  line-height: 1em;
}


.no-header {
  border-radius: 1rem;
}

.exp-header-table {
  table-layout: fixed;
}

.tinted {
  /*padding-top: 0.1rem;*/
  border-radius: 1.1rem;
  background-color: var(--tint-color);
  margin: 0rem;
  padding: 0rem;
  margin-bottom: 1rem;
  border: 1px solid darkslategray;
  box-sizing: content-box;
  position: relative; 
  /*bottom: -2px;*/

}

.tinted2 {
  /*padding-top: 0.1rem;*/
  border-radius: 1rem;
  background-color: var(--tint-color-2);
  /*background: url('brick.png') repeat bottom left;*/
  margin: 0rem;
  padding: 0rem;
  border: 2px solid darkslategray;
  box-sizing: border-box;
  position: relative;
}

.bottom-border-fix {
  border-bottom: 3px solid darkslategray;
}

h2 {
  line-height: 1.1em;
}


#message-status-area {
  position: relative;
  border-radius: 0.3em;
  padding: 1em;
  min-width: 270px;
}

#message-status-area.status-ok {
  background-color: #0cf10ca1;
  border: 2px solid greenyellow;
}

#message-status-area.status-nok {
  background-color: #e33213ca;
  border: 1px solid red;
}

#message-status-area.status-nokhum {
  background-color: #e33213ca;
  border: 1px solid red;
}

/*
.status-ok {
  background-color: greenyellow;
}

.status-nok {
  background-color:red;
}
*/



 /* phones */
 @media only screen and (max-width: 600px) {

 }

 /* portrait tablets, large phones */
 @media only screen and (min-width: 600px) {

 }
 
footer div.right {
  display: none;
}

 /* landscape tablets, other medium devices */
 @media only screen and (min-width: 768px) {
 
 }

 @media only screen and (min-width: 700px) {
  footer div.right {
     display: block;
     margin-left: 0;
     padding-left: 0;
     margin-right: 0.3em;
     padding-right: 0.3em;
  }
}
 
.inline-input-container input {
  /*position: relative;
  left: 10rem;*/
  width: 15rem;
  /*display: inline-block;*/
  /*margin: 0px auto;*/
  text-align: left;
}

.do-not-show {
  display: none;
}

.inline-input {
  position: absolute;
  left: 23rem;
  /*width: 15rem;
  display: inline-block;*/
  margin-top: 0.3rem;
  display: none;
}

.hide-when-wide {
  display: inline-block;
}
.show-when-wide {
  display: none;
}

 /* small laptops/desktops */
 @media only screen and (min-width: 992px) {
  .hide-when-wide {
    display: none;
  }
  .inline-input {
    display: inline-block;
  }
  .show-when-wide {
    display: inline-block;
  }
 }
 
 /* larger laptops and desktops */
 @media only screen and (min-width: 1200px) {

 } 

@media screen and (min-width: 1600px) {
  .text-content {
    height: calc(100% - 20em - 256px);
    line-height: 3rem;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 8rem;
    margin-left: 5%;
    margin-right: 5%; 
    margin-top: 2rem; 
  }
  .td1 svg {
    font-size: 2.4rem;
    position: relative;
    top: -1px;
    left: -1px;
  }
  
}

@media screen and (max-width: 845px) {
  a {
    width: 9em;
  }
  nav button {
    height: 2em;
  }
  /*
  .sendFormButton {
    width: 96%;
  }
  */
}

@media screen and (max-width: 530px) {
  h1 {
    margin-left: 100px;
    max-width: 200px;
  }
  nav {
    font-size: 1.5em;
    max-height: 4.5em;
  }
  nav button {
    height: 2.5rem;
  }
  .text-content {
    height: calc(100% - 20em - 256px);
    line-height: 1.4rem;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 8rem;
    margin-left: 5%;
    margin-right: 5%; 
    margin-top: 2rem; 
  }
  
  footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5.5rem;
  }
  
  footer div.left {
    margin: 0;
  }
  .imstrip {
    display: none;
  }
  
}



@media screen and (max-width: 400px) {
  .author-name {
    display: none;
  }
  footer div.left {
    padding-top: 1.5rem;
  }
}

@media screen and (max-height: 400px) {
  .imstrip {
    display: none;
  }
}


@media screen and (max-width: 400px) {
  .text-content {
    height: calc(100% - 20em - 256px);
    line-height: 1.4rem;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 8rem;
    margin-left: 5%;
    margin-right: 5%; 
    margin-top: 2rem; 
  }
  .tinted2 h3 {
    /*color: red;*/
    font-size: 1rem;
  }
  nav button {
    height: 2.5rem;
  }
}


div, table, tr, td {
  margin: 0px;
  padding: 0px;
}

table {
  width: 100%;
}

.td0 {
  width: 80%;
}
.td1 {
  width: 15%;
}






.col-adjust-para {
  display: block;
}


p {
  text-align: left;
  padding-left: 20px;
}

nav {
  /*background: #99bdee88;*/
  /*background: url('tre3.png') no-repeat bottom left;*/
  background: url('tre3_16_50_75.jpg') no-repeat bottom left;
  background-size: cover;

  padding-left: 0!important;
  width: 100%;

  max-height: 4.5em;

  
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 15s linear;
  }
}

.App-header {
  /*background-color: #5dbdad88;*/
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: black;
}

.show-when-little-vertical-space-space {
  display: none;
}

@media screen and (orientation: landscape) and (max-height: 680px) {
  .imstrip {
    display: none;
  }
  .hide-when-little-vertical-space {
    display: none;
  }
  .show-when-little-vertical-space-space {
    display: inline-block;
    width: 1.5rem;
  }
  footer {
    height: 4rem;
  }
}

@media screen and (orientation: landscape) and (min-height: 1800px) {
  .App-header {
    font-size: 3.15rem;
  }
}
@media screen and (orientation: portrait) and (min-width: 2040px) {
  .App-header {
    font-size: 3.15rem;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul {
  margin-bottom: 0;
}

li {
  margin-bottom: 0.4em;
  margin-top: 0.4em;
}

